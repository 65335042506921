
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixPrice, utcToLocal } from '@/utils/general'
  import { Price } from '@/entities/sales'

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
  methods: { fixPrice, utcToLocal },
})
  export default class LastPrice extends Vue {
  @Prop() value!: Price[]
  @Prop({ type: Boolean, default: false }) opened: boolean

  panel? = -1

  get sortedPrices () {
    if (!this.value.length) return []
    return this.value.sort((prev, next) => {
      if (prev.isLastPrice) return -1
      if (next.isLastPrice) return 1

      return prev.expiration.diff(next.expiration, 'day')
    })
  }

  get lastPrice () {
    if (!this.value.length) return

    return this.value.find(price => price.isLastPrice)
  }

  @Watch('opened', { immediate: true }) onChange (val) {
    this.panel = val ? 0 : -1
  }
  }
